import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import SVGInject from "@iconfu/svg-inject/dist/svg-inject.min.js";

import Header from "./components/Header";
import Homepage from "./components/Homepage";
import MvpDevelopmentSingapore from "./components/mvp-development/mvpDevelopmentSingapore";
import TravelAppSingaporeTampines from "./components/singapore/TravelAppSingaporeTampines";
import TravelAppSingapore from "./components/singapore/TravelAppSingapore";
import OcrSingapore from "./components/singapore/OcrSingapore";
import IotSingaporeTampines from "./components/singapore/IotSingaporeTampines";
import TravelExperienceSingapore from "./components/singapore/TravelExperienceSingapore";
import IotSingaporeSolution from "./components/singapore/IotSingaporeSolution";
import About from "./components/About";
import Appservice from "./components/service/Appservice";
// import Webservice from "./components/service/Webservice";
// import MvpSolution from "./components/MvpSolution";
// import OcrDevelopment from "./components/OcrDevelopment";
// import IotSolution from "./components/IotSolution";
// import AimlSolution from "./components/AimlSolution";
// import Customservice from "./components/service/Customservice";
import Appdev from "./components/hireteam/Appdev";
import Webdev from "./components/hireteam/Webdev";
import Career from "./components/Career";

import Casestudies from "./components/Casestudies";
import CasestudySeaqua from "./components/case-studies-details/CasestudySeaqua";
import AssetControl from "./components/case-studies-details/AssetControl";
import AmazingCarRental from "./components/case-studies-details/AmazingCarRental";
import RoyalCar from "./components/case-studies-details/RoyalCar";

import Blog from "./components/Blog";
import IotBlog from "./components/blogs/IotBlog";
import AiFarming from "./components/blogs/AiFarming";
import Nftblog from "./components/blogs/Nftblog";

import Contact from "./components/Contact";
import Termsofuse from "./components/Termsofuse";
import Pagenotfound from "./components/Pagenotfound";
import Footer from "./components/Footer";
import Pageprogress from "./components/Pageprogress";

import AiAppTampinesSingapore from "./components/aiml/AiAppTampinesSingapore";
import DeepLearningTampinesSingapore from "./components/aiml/DeepLearningTampinesSingapore";
import AiDevelopmentTampinesSingapore from "./components/aiml/AiDevelopmentTampinesSingapore";
import AiTampinesSingapore from "./components/aiml/AiTampinesSingapore";
import AiSolutionTampinesSingapore from "./components/aiml/AiSolutionTampinesSingapore";
import MlTampinesSingapore from "./components/aiml/MlTampinesSingapore";

import AiOttawa from "./components/canada/AiOttawa";
import AiCanada from "./components/canada/AiCanada";
import OcrCanada from "./components/canada/OcrCanada";
import OcrOttawa from "./components/canada/OcrOttawa";
import IotOttawa from "./components/canada/IotOttawa";
import IotCanada from "./components/canada/IotCanada";
import FoodDeliveryCanada from "./components/canada/FoodDeliveryCanada";
import DeepLearningCanada from "./components/canada/DeepLearningCanada";
import MlCanada from "./components/canada/MlCanada";
import MlOttawa from "./components/canada/MlOttawa";

import IotCapeTownDevelopment from "./components/southAfrica/IotCapeTownDevelopment";
import OcrDevelopmentSouthAfrica from "./components/southAfrica/OcrDevelopmentSouthAfrica";
import FoodDeliverySouthAfrica from "./components/southAfrica/FoodDeliverySouthAfrica";
import FoodDeliveryCapeTown from "./components/southAfrica/FoodDeliveryCapeTown";

import IotUAE from "./components/UAE/IotUAE";
import OcrAbuDhabi from "./components/UAE/OcrAbuDhabi";
import ToursTravelAbuDhabi from "./components/UAE/ToursTravelAbuDhabi";
import EcommerceUAE from "./components/UAE/EcommerceUAE";
import RfidUAE from "./components/UAE/RfidUAE";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  });

  useEffect(() => {
    var theme = {
      init: function () {
        theme.svgInject();
      },
      svgInject: () => {
        SVGInject.setOptions({
          onFail: function (img, svg) {
            img.classList.remove("svg-inject");
          },
        });
        SVGInject(document.querySelectorAll("img.svg-inject"), {
          useCache: true,
        });
      },
    };
    theme.init();
    const performScript = document.createElement("script");
    performScript.src = "./assets/js/perform.js";
    performScript.async = true;
    document.body.appendChild(performScript);

    const pluginsScript = document.createElement("script");
    pluginsScript.src = "./assets/js/plugins.js";
    pluginsScript.async = true;
    document.body.appendChild(pluginsScript);

    const themeScript = document.createElement("script");
    themeScript.src = "./assets/js/theme.js";
    themeScript.async = true;
    document.body.appendChild(themeScript);

    return () => {
      document.body.removeChild(performScript);
      document.body.removeChild(pluginsScript);
      document.body.removeChild(themeScript);
    };
  }, [location.pathname]);

  return (
    <>
      <div className="content-wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/mvp-development-singapore" element={<MvpDevelopmentSingapore />} />
          <Route path="/travel-app-singapore-tampines" element={<TravelAppSingaporeTampines />} />
          <Route path="/travel-app-singapore" element={<TravelAppSingapore />} />
          <Route path="/ocr-singapore" element={<OcrSingapore />} />
          <Route path="/iot-singapore-tampines" element={<IotSingaporeTampines />} />
          <Route path="/travel-experience-singapore" element={<TravelExperienceSingapore />} />
          <Route path="/iot-singapore-solution" element={<IotSingaporeSolution />} />
          <Route path="/ai-app-tampines-singapore" element={<AiAppTampinesSingapore />} />
          <Route
            path="/deep-learning-tampines-singapore"
            element={<DeepLearningTampinesSingapore />}
          />
          <Route
            path="/ai-development-tampines-singapore"
            element={<AiDevelopmentTampinesSingapore />}
          />
          <Route path="/ai-tampines-singapore" element={<AiTampinesSingapore />} />
          <Route path="/ai-solution-tampines-singapore" element={<AiSolutionTampinesSingapore />} />
          <Route path="/ml-tampines-singapore" element={<MlTampinesSingapore />} />

          {/* canada */}
          <Route
            path="/artificial-intelligence-development-services-ottawa"
            element={<AiOttawa />}
          />
          <Route
            path="/artificial-intelligence-development-services-canada"
            element={<AiCanada />}
          />
          <Route path="/ocr-development-services-ottawa" element={<OcrOttawa />} />
          <Route path="/ocr-development-services-canada" element={<OcrCanada />} />
          <Route path="/iot-development-services-ottawa" element={<IotOttawa />} />
          <Route path="/iot-development-services-canada" element={<IotCanada />} />
          <Route
            path="/food-delivery-app-development-services-canada"
            element={<FoodDeliveryCanada />}
          />
          <Route
            path="/deep-learning-development-services-canada"
            element={<DeepLearningCanada />}
          />
          <Route path="/machine-learning-development-services-canada" element={<MlCanada />} />
          <Route path="/machine-learning-development-services-ottawa" element={<MlOttawa />} />

          {/* south africa */}
          <Route path="/iot-development-services-cape-town" element={<IotCapeTownDevelopment />} />
          <Route
            path="/ocr-development-services-south-africa"
            element={<OcrDevelopmentSouthAfrica />}
          />
          <Route
            path="/food-delivery-app-development-services-south-africa"
            element={<FoodDeliverySouthAfrica />}
          />
          <Route
            path="/food-delivery-app-development-services-cape-town"
            element={<FoodDeliveryCapeTown />}
          />

          {/* UAE */}
          <Route
            path="/tours-and-travels-app-development-services-abu-dhabi"
            element={<ToursTravelAbuDhabi />}
          />
          <Route path="/e-commerce-development-services-uae" element={<EcommerceUAE />} />
          <Route path="/iot-development-services-uae" element={<IotUAE />} />
          <Route path="/ocr-development-services-abu-dhabi" element={<OcrAbuDhabi />} />
          <Route path="/rfid-development-services-uae" element={<RfidUAE />} />

          <Route path="/about" element={<About />} />
          <Route path="/mobile-app-solution" element={<Appservice />} />
          <Route path="/web-solution" element={<Appservice />} />
          <Route path="/custom-solution" element={<Appservice />} />
          <Route path="/application-development" element={<Appdev />} />
          <Route path="/web-development" element={<Webdev />} />
          {/* <Route path="/byteztech-mvp-solution" element={<MvpSolution />} />
          <Route path="/byteztech-ocr-development" element={<OcrDevelopment />} />
          <Route path="/byteztech-iot-solution" element={<IotSolution />} />
          <Route path="/byteztech-ai-ml-solution" element={<AimlSolution />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/casestudies" element={<Casestudies />} />
          <Route path="/casestudy-seaqua" element={<CasestudySeaqua />} />
          <Route path="/asset-control" element={<AssetControl />} />
          <Route path="/amazing-car-rental" element={<AmazingCarRental />} />
          <Route path="/royal-car" element={<RoyalCar />} />
          <Route path="/blogs" element={<Blog />} />
          <Route
            path="/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"
            element={<IotBlog />}
          />
          <Route
            path="/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"
            element={<AiFarming />}
          />
          <Route
            path="/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"
            element={<Nftblog />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-and-condition" element={<Termsofuse />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </div>
      <Footer />
      <Pageprogress />
    </>
  );
}

export default App;
