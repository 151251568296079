import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

const Contact = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);
  const Title = "contact"
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    contactNo: "",
    businessEmail: "",
    projectDetails: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error("reCAPTCHA not completed");
      return;
    }

    axios
      .post(
        "https://byteztech.com/server/submit",
        {
          ...formData,
          Title,
          recaptchaValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setFormData({
          fullName: "",
          companyName: "",
          contactNo: "",
          businessEmail: "",
          projectDetails: "",
        });
        setRecaptchaValue(null)
      })
      .catch((error) => {
        console.error(error);
      });

  };

  return (
    <>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gx-lg-8 gx-xl-12 align-items-center">
            <div className="col-lg-7 order-lg-2 position-relative">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/i14.png"
                  srcSet="./assets/img/illustrations/i14@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-5">
              <h2 className="display-4 mb-3">Let’s Talk</h2>
              <p className="lead fs-lg">
                Let's make something great together. We are{" "}
                <span className="underline purple">trusted by</span> over 121+ clients. Join them by
                using our services and grow your business.
              </p>
              <p>
                At BytezTech, we believe in fostering enduring partnerships. Whether you're eyeing
                the next disruptive application or seeking to revamp your business processes, we're
                here to help you navigate through. Reach out to us; let's craft success stories
                together, one innovative solution at a time.
              </p>
              <Link to="" className="btn btn-purple rounded-pill mt-2">
                Join Us
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-7">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/i5.png"
                  srcSet="./assets/img/illustrations/i5@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">
                Get In Touch
              </h2>
              <h3 className="display-5 mb-7">Got any questions? Don't hesitate to get in touch.</h3>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-4 mt-n1">
                    {" "}
                    <i className="uil uil-location-pin-alt"></i>{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Address</h5>
                  <address>
                    607-608 BytezTech, Rio Business Hub, Near Yamuna Chowk, Mota Varachha, Surat,
                    Gujarat 394101
                  </address>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-4 mt-n1">
                    {" "}
                    <i className="uil uil-phone-volume"></i>{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Phone</h5>
                  <p>+91 6351100121</p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-4 mt-n1">
                    {" "}
                    <i className="uil uil-envelope"></i>{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">E-mail</h5>
                  <p className="mb-0">
                    <Link to="mailto:Info@byteztech.in" className="link-body">
                      Info@byteztech.in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="wrapper image-wrapper bg-image bg-overlay"
          data-image-src="./assets/img/photos/bg36.webp"
        >
          <div className="container py-15 py-md-17">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <div className="card border-0 bg-white-900">
                  <div className="card-body py-lg-13 px-lg-16">
                    <h2 className="display-5 mb-3 text-center">
                      Get Your Custom Development Quote
                    </h2>
                    <p className="lead fs-lg text-center mb-10">
                      For more information please get in touch using the form below:
                    </p>
                    <Form className="contact-form" onSubmit={handleSubmit}>
                      <div className="row gx-4">
                        <div className="col-md-6">
                          <Form.Floating className="mb-4">
                            <Form.Control
                              type="text"
                              name="fullName"
                              value={formData.fullName}
                              onChange={handleChange}
                              placeholder="Full name"
                              required
                            />
                            <label htmlFor="form_name">Full name *</label>
                          </Form.Floating>
                        </div>
                        <div className="col-md-6">
                          <Form.Floating className="mb-4">
                            <Form.Control
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                              placeholder="Company name"
                              required
                            />
                            <label htmlFor="form_name">Company name *</label>
                          </Form.Floating>
                        </div>
                        <div className="col-md-6">
                          <Form.Floating className="mb-4">
                            <Form.Control
                              type="number"
                              name="contactNo"
                              value={formData.contactNo}
                              onChange={handleChange}
                              placeholder="Mobile number"
                            />
                            <label htmlFor="form_name">Mobile number</label>
                          </Form.Floating>
                        </div>
                        <div className="col-md-6">
                          <Form.Floating className="mb-4">
                            <Form.Control
                              type="email"
                              name="businessEmail"
                              value={formData.businessEmail}
                              onChange={handleChange}
                              placeholder="jane.doe@example.com"
                              required
                            />
                            <label htmlFor="form_email">Email *</label>
                          </Form.Floating>
                        </div>
                        <div className="col-12">
                          <Form.Floating className="mb-4">
                            <Form.Control
                              as="textarea"
                              name="projectDetails"
                              value={formData.projectDetails}
                              onChange={handleChange}
                              placeholder="Project description"
                              style={{ height: "150px" }}
                              required
                            />
                            <label htmlFor="form_message">Project description *</label>
                          </Form.Floating>
                        </div>
                        <div className="col-12 mb-6 text-center">
                          <ReCAPTCHA
                            sitekey="6Lc7JyUoAAAAAFzRHSzWyxCBsMb8EapJwRVNg5gG"
                            onChange={(value) => setRecaptchaValue(value)}
                          />
                          {!recaptchaValue ? (
                            <h6
                              className="mt-4 mx-1 text-start"
                              style={{ color: "red", fontSize: 12 }}
                            >
                              ! reCAPTCHA not completed
                            </h6>
                          ) : null}
                        </div>
                        <div className="col-12 text-center">
                          <Button type="submit" className="btn btn-primary rounded-pill btn-send">
                            Send message
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
